import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, ConfirmationComponent, Department, DepartmentService, Pager, TeamService } from 'lib-trend-core';
import { mergeMap } from 'rxjs';

@Component({
  selector: 'department-component',
  templateUrl: 'department.component.html'
})
export class DepartmentComponent extends AbstractComponent implements OnInit {

  pager: Pager<Department> = new Pager<Department>({ perPage: 10 });

  private totalAttendants!: Array<any>;

  constructor(
    public injector: Injector,
    public dialog: MatDialog,
    public departmentService: DepartmentService,
    public teamService: TeamService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getList();
  }

  private getList() {
    if (this.isAdmin()) {
      this.searchParams = {
        company: this.getIDCurrentCompany()
      };
      this.departmentService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams)
        .pipe(
          mergeMap((pager: Pager<Department>) => {
            this.pager = pager;

            const ids = this.pager.list.map((department: Department) => department._id);
            return this.departmentService.getCountAttendantsByDepartment(ids);
          }),
        ).subscribe((counts: any) => {
          this.totalAttendants = counts;
        });
    } else {
      this.searchParams = {
        company: this.getIDCurrentCompany(),
        user: this.getIDCurrentUser()
      };
      this.teamService.getDepartmentFromSupervisor(this.pager.page, this.pager.perPage, this.searchString, this.searchParams)
        .pipe(
          mergeMap((pager: Pager<Department>) => {
            this.pager = pager;

            const ids = this.pager.list.map((department: Department) => department._id);
            return this.departmentService.getCountAttendantsByDepartment(ids);
          }),
        ).subscribe((counts: any) => {
          this.totalAttendants = counts;
        });
    }
  }

  getCountAttendants(departmentId: string): string {
    if (!this.totalAttendants) return 'Nenhum atendente\nNenhum supervisor';

    const department = this.totalAttendants.find((dept: Department) => dept._id === departmentId);

    const totalAgents = department?.team.reduce((sum: number, team: {
      _id: string,
      name: string,
      supervisorCount: number,
      agentCount: number
    }) => sum + team.agentCount, 0) ?? 0;

    const totalSupervisors = department?.team.reduce((sum: number, team: {
      _id: string,
      name: string,
      supervisorCount: number,
      agentCount: number
    }) => sum + team.supervisorCount, 0) ?? 0;

    const agentText = totalAgents === 0 ? 'Nenhum Atendente' : totalAgents > 1 ? `${totalAgents} Atendentes` : `${totalAgents} Atendente`;
    const supervisorText = totalSupervisors === 0 ? 'Nenhum Supervisor' : totalSupervisors > 1 ? `${totalSupervisors} Supervisores` : `${totalSupervisors} Supervisor`;

    return `${agentText}\n${supervisorText}`;
  }

  addDepartment() {
    this.router.navigate(['/department/new'], { relativeTo: this.route });
  }

  edit(item: Department): void {
    this.router.navigate([`/department/${item._id}/edit`], { relativeTo: this.route });
  }

  delete(item: Department): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.departmentService.delete(item._id).subscribe({
          next: (value) => {
            this.alertService.success('Departmento excluído com sucesso.');
            this.getList();
          },
          error: (err) => this.alertService.error(err.error.message)
        });
      }
    });
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

}
